/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();


// color
$white: #ffffff;
$primary-color: #4C214D;
$primary-color-1: #2F0135;
$secondary-color: #FDC372;
$secondary-color-1: #F5C47E;
$secondary-color-2: #DBA04F;
$secondary-color-3: #D3A25D;


$primary-text-color: #4C214D;
$basic-text-color: #707070;

$text-color-2: #777777;
$text-color-3: #9897A9;


$primary-text : #FFFFFF;
$accent-text : #4C214D;
$light-text-color: #C5C6D0;
$bg-section: #F3EFF2;

$C500: #D1D1D1;
$C600: #C5C6CF;
$C800 :#333333;
$C900 :#444444;
$dti-palette: (
  50: #4C214D,
  100: #FDC372,
  200: #80deea,
  300: #3E7409,
  400: #26c6da,
  500: #00bcd4,
  600: #fff,
  700: #0097a7,
  800: #00838f,
  900: #fff,
  A100: #84ffff,
  A200: #18ffff,
  A400: #00e5ff,
  A700: #00b8d4,
  contrast: (50: $primary-text,
    100: $accent-text,
    200: $primary-text,
    300: $primary-text,
    400: $primary-text,
    500: $light-text-color,
    600: $light-text-color,
    700: $light-text-color,
    800: $light-text-color,
    900: $light-text-color,
    A100: $primary-text,
    A200: $primary-text,
    A400: $primary-text,
    A700: $primary-text,
  )
);

$my-primary: mat.define-palette($dti-palette, 50);
$my-accent: mat.define-palette($dti-palette, 100, A100, A400);
$success: mat.define-palette($dti-palette, 100, A100, A400);

$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      density: 0,
    ));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($my-theme);

// Include the theme mixins for other components you use here.
@include mat.all-component-themes($my-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat';
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: .875rem
}

::-webkit-scrollbar-track {
  border-radius: .875rem;
  background: $white;

}

::-webkit-scrollbar-thumb {
  background: $C600;
  border-radius: .875rem;
}

.material-icons-font {
  font-family: 'Material Icons';
}

.modal-open {
  overflow-y: hidden !important;
}

// #region LAYOUTS
.container {
  width: 100%;
  margin: 0 auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-50 {
  flex-grow: .5;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.mt-1 {
  margin-top: 1rem;
}

.p {
  padding: 1rem;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: 2rem;
}

.p-50 {
  padding: 0.5;
}

.pt-50 {
  padding-top: .5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-1-50 {
  padding-top: 1.5rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pt-5-50 {
  padding-top: 5.50rem;
}

.pr-0 {
  padding-right: 0;
}

.pl-50 {
  padding-left: .5rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-50 {
  padding-right: .5rem;
}

.pb-50 {
  padding-bottom: .5rem;
}

// #endregion

// #region Colors
.secondary-color {
  color: $secondary-color;
}

// #endregion Colors

// #region ALIGNMENT
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// #endregion


// #region SIZES
.field-label-size {
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 600;
}

.field-size {
  font-size: 13px !important;
  line-height: 16px !important;
}



.size-8 {
  font-size: 35px;
  line-height: 50px;
}


.border {
  border: 0.0625rem solid #D5C6D1;
}

.pointer:hover {
  cursor: pointer;
}

// #endregion



// #region Angular Material

// Mat-Stepper Override Layout.

::ng-deep .mat-step-text-label {
  white-space: break-spaces;
  text-align: center;
  font: normal normal normal 13px/16px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  width: 7.1875rem;
  color: $C500;
}

::ng-deep .mat-step-label.mat-step-label-active.mat-step-label-selected .mat-step-text-label {
  font: normal normal 700 13px/16px Montserrat;
  color: $primary-color !important;
}

::ng-deep .mat-step-label.mat-step-label-active:not(.mat-step-label-selected) .mat-step-text-label {
  font: normal normal 500 13px/16px Montserrat;
  color: $primary-color !important;
}

::ng-deep .mat-step-icon.mat-step-icon-state-edit {
  background-color: #FEE1B8 !important;
}

::ng-deep .mat-step-label.mat-step-label-active {
  color: $primary-color !important;
}

::ng-deep .mat-step-icon.mat-step-icon-state-number {
  background-color: $C500;
  color: #FFFFFF !important;
}

::ng-deep .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
  color: $primary-color !important;
}

::ng-deep .mat-step-icon {

  font: normal normal bold 14px/18px Montserrat;
  height: 3rem !important;
  width: 3rem !important;
  z-index: 1;
}

::ng-deep .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
::ng-deep .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before,
::ng-deep .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 3rem;
}

:host ::ng-deep .mat-horizontal-content-container {
  padding: 0;
  background: #F3EFF2 0% 0% no-repeat padding-box;
}

::ng-deep .mat-mdc-form-field {
  display: block !important;
}

::ng-deep .mat-mdc-form-field:not(.text-area) .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
  height: 2.625rem;
}

::ng-deep .mdc-floating-label.mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
  top: 18px !important;
}

::ng-deep .mat-mdc-form-field:not(.text-area) .mat-mdc-form-field-infix {
  height: 42px;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

//Mat-Select > mat-option layout
.mat-select-option:active,
.mat-select-option:hover {
  background: rgb(253 195 114 / 20%) 0% 0% no-repeat padding-box !important;
  color: $C800 !important;
}

// Mat-Checbox
::ng-deep .mat-mdc-checkbox>.mdc-form-field>label {
  text-align: left;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: $C800;
  opacity: 1;
}

::ng-deep .mat-mdc-form-field-error-wrapper {
  padding: 0 5px !important;
}

// #endregion

// #region User Profile Menu
.user-menu {
  color: #fff;
  background-color: #1f1f1f;
  max-width: 100% !important;

  >div:first-child {
    padding-bottom: 0;
  }

  .user-menu-container {
    padding-bottom: .625rem;
    padding-top: .3125rem;
    opacity: 1 !important;

    span:first-child {
      display: flex;
    }

    .user-initial {
      height: 2.5rem;
      width: 2.5rem;
      background-color: #EEEEEE;
      border-radius: 50%;
      margin-right: 0.5rem;
      color: #813882;
      font: normal normal 600 1.25rem/2.5rem Montserrat;
      display: flex;
      justify-content: center;
      vertical-align: middle;
      letter-spacing: 0.28px;
      opacity: 1;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      text-align: left;
      opacity: 1;
      color: #FFFFFF;

      .name {
        font: normal normal bold 14px/20px Montserrat;
        letter-spacing: .0131rem;
      }

      .email {
        font: normal normal 200 11px/17px Montserrat;
        letter-spacing: .0094rem;
      }
    }

  }

  .logout-menu {
    border-top: .6px solid #fff;
    letter-spacing: 0.18px;
    min-height: 40px;
    padding: 0 25px;

    span {
      color: #FFFFFF;
      font: normal normal 300 14px/40px Montserrat !important;
    }

    .logout-icon {
      color: #C5C6D0 !important;
      height: 1.25rem;
      width: 1.25rem;
      font-size: 1.25rem
    }

    &:hover {
      background-color: #333333 !important;
    }
  }
}
// #endregion

.mat-mdc-tab-header {
  border-bottom: 2px solid #DBA04F;
}